import { Navigate, type RouteObject } from 'react-router-dom';

const shipfastRoutes: RouteObject = {
  path: 'shipping',
  lazy: () => import('@/pages/shipfast/layout'),
  children: [
    {
      index: true,
      element: <Navigate to="dashboard" />,
    },
    {
      path: 'dashboard',
      lazy: () => import('@/pages/shipfast/pages/dashboard'),
    },
    {
      path: 'dashboard/onboarding',
      lazy: () => import('@/pages/shipfast/pages/dashboard/onboarding'),
    },
    {
      path: 'orders',
      lazy: () => import('@/pages/shipfast/pages/orders'),
    },
    {
      path: 'pickups',
      children: [
        {
          index: true,
          lazy: () => import('@/pages/shipfast/pages/manifests'),
        },
        {
          path: ':id',
          lazy: () => import('@/pages/shipfast/pages/manifest_details'),
        },
      ],
    },
    {
      path: 'billing',
      lazy: () => import('@/pages/shipfast/pages/billing'),
    },
    {
      path: 'returns',
      lazy: () => import('@/pages/shipfast/pages/returns'),
    },
    {
      path: 'analytics',
      lazy: () => import('@/pages/shipfast/pages/analytics'),
    },
    {
      path: 'rules',
      lazy: () => import('@/pages/shipfast/pages/rules'),
    },
    {
      path: 'support',
      lazy: () => import('@/pages/shipfast/pages/support'),
    },
    {
      path: 'recharge',
      lazy: () => import('@/pages/shipfast/pages/recharge'),
    },
  ],
};

export default shipfastRoutes;
